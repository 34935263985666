import { Layout } from 'antd';
import { Content } from 'antd/lib/layout/layout';

import Header from 'components/headers/Header';
import ProjectSidebar from 'components/sidebars/ProjectSidebar';
import { Outlet } from 'react-router-dom';

import styles from './layouts.module.scss';
const { Sider } = Layout;

const ProjectsLayout = ({ tokenData }) => {
  return (
    <Layout style={{ backgroundColor: '#fff' }}>
      <Header showNav={false} />
      <Layout className={styles.layoutWrapper} hasSider>
        <Sider className={styles.sidebar}>
          <ProjectSidebar tokenData={tokenData} />
        </Sider>
        <div className={styles.layoutContent}>
          <Layout className={styles.layoutContentInner}>
            <Content>
              <Outlet />
            </Content>
          </Layout>
        </div>
      </Layout>
    </Layout>
  );
};

export default ProjectsLayout;

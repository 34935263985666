/* eslint-disable jsx-a11y/anchor-is-valid */
import { PageHeader, Popover } from 'antd';
import { Dispatch, SetStateAction } from 'react';
import styles from '../models.module.scss';
import CreateFieldDrawer from './field-configuration/CreateFieldDrawer';

type ModelsRightSidebarProps = {
  repeatedFieldIdentifier: string;
  setPopover: Dispatch<SetStateAction<boolean>>;
  popover: boolean;
};

const HeaderTitle = (objectIdentifier: string) => {
  if (objectIdentifier !== '') {
    return (
      <>
        Add Fields to <span style={{ color: '#9f1329' }}>{objectIdentifier.toUpperCase()}</span>{' '}
        Schema
      </>
    );
  } else {
    return <span style={{ color: '#E73A55' }}>Add Root(/) Fields </span>;
  }
};

const ModelsRightSidebar = ({
  repeatedFieldIdentifier,
  setPopover,
  popover,
}: ModelsRightSidebarProps) => {
  return (
    <div className={styles.modelSidebarInner}>
      <PageHeader
        title={HeaderTitle(repeatedFieldIdentifier)}
        subTitle={
          repeatedFieldIdentifier !== '' ? null : (
            <a target="_" href="https://apito.io/docs/model/database-design">
              documentation
            </a>
          )
        }
      >
        <Popover
          content={
            <div>
              <div>
                <a
                  target="_"
                  href="https://docs.apito.io/console/models-fields#creating-relations-between-models"
                >
                  How to model your project guide
                </a>
              </div>
              <div
                style={{
                  marginTop: '10px',
                }}
              >
                <a onClick={() => setPopover(false)}>Close</a>
              </div>
            </div>
          }
          title="Choose Any Field Type"
          trigger="click"
          open={popover}
          placement="leftTop"
        >
          <CreateFieldDrawer repeatedFieldIdentifier={repeatedFieldIdentifier} />
        </Popover>
      </PageHeader>
    </div>
  );
};

export default ModelsRightSidebar;

import { useCallback, useState } from 'react';
import { Button, Checkbox, Col, Divider, Form, Input, Row, Select, Typography } from 'antd';

import debounce from 'lodash/debounce';

import styles from './projectCreateForm.module.scss';
import { PROJECT_CREATE, PROJECT_NAME_CHECK } from 'configs';
import ProjectDriverForm from './ProjectDriverChoice';
import { useNavigate } from 'react-router-dom';
import { httpService } from 'utils/httpService';
import { ProjectModel } from 'generated/graphql';
import { errorMessage, successMessage } from 'utils/message';
import PlanCard from 'components/cards/PlanCard';
import { PlanData } from 'dto/plan';
import { PLAN_LIST } from 'constants/plansData';
import FixedListSelector from 'components/inputs/FixedListSelector';
import { DEMO_PROJECTS } from 'data/demo_projects';
const { Title, Text } = Typography;
const { TextArea } = Input;

const { Option } = Select;

const validateMessages = {
  // eslint-disable-next-line no-template-curly-in-string
  required: '${label} is required!',
};

export interface ProjectCreateRequest {
  id: string;
  name: string;
  description: string;
  policy: string;
  driver: string;
  example: string;
}

type ProjectCreateFormProps = {
  // props
};

// eslint-disable-next-line no-empty-pattern
const ProjectCreateForm = ({}: ProjectCreateFormProps) => {
  const [isCheckingIdentifier, setIsCheckingIdentifier] = useState(false);
  const [isValidIdentifier, setIsValidIdentifier] = useState(false);

  const [payload, setPayload] = useState<ProjectModel>();

  const [projectType, setProjectType] = useState<string>('');
  const [selectedPlan, setSelectedPlan] = useState<PlanData>(PLAN_LIST[0]);

  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const createProject = async () => {
    setLoading(true);
    await httpService
      .post(PROJECT_CREATE, payload)
      .then((res) => {
        //const singleProjectData = res?.data?.body || {};
        //setCreatedProjectId(singleProjectData?.id);
        successMessage('Project Created Successfully');
      })
      .catch((err) => {
        errorMessage(err?.response?.data?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // const [selectedEngine, setSelectedEngine] = useState<string>('');

  let checkIdentifier = (identifier) => {
    setIsCheckingIdentifier(true);
    httpService
      .post(PROJECT_NAME_CHECK, {
        name: identifier,
      })
      .then((res) => {
        setIsValidIdentifier(true);
        setIsCheckingIdentifier(false);
      })
      .catch((err) => {
        setIsValidIdentifier(false);
        setIsCheckingIdentifier(false);
      });
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  checkIdentifier = useCallback(debounce(checkIdentifier, 800), []);

  const spaceReplace = (str) => str.trim().replaceAll(/[^0-9a-zA-Z]+/g, '_');

  function makeId(length) {
    let result = '';
    const characters = '0123456789abcdefghijklmnopqrstuvwxyz';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  const getIdentifierWithRandomNumber = (projectName) =>
    `${spaceReplace(projectName).toLowerCase()}_${makeId(5)}`;

  const onFinish = async (values) => {
    //console.log('createProject', values);
    if (values) {
      createProject();
    }
  };

  const onValuesChange = async (values) => {
    if (values?.name && payload?.name !== values?.name) {
      const id = await getIdentifierWithRandomNumber(values?.name);
      form.setFieldsValue({
        id,
      });
      setPayload({
        ...payload,
        ...values,
        id,
      });
      checkIdentifier(id);
    } else if (values?.name === '') {
      form.setFieldsValue({
        id: '',
      });
      setPayload({ ...payload, ...values });
    } else {
      setPayload({ ...payload, ...values });
    }
  };

  console.log('projectType', projectType);

  return (
    <>
      <div style={{ marginBottom: '30px' }}>
        <Title level={5}>
          <Text>Fill Up Project Details</Text>
        </Title>
      </div>
      <Form
        name="new-project"
        layout="vertical"
        onValuesChange={onValuesChange}
        onFinish={onFinish}
        validateMessages={validateMessages}
        initialValues={{ remember: false }}
        style={{ height: '100%' }}
        form={form}
        requiredMark={false}
      >
        <Row gutter={24}>
          <Col md={8}>
            <div>
              {/* {projectType !== null ? (
                <Form.Item
                  label="Project Template"
                  extra="Templates are good choice to jump start your project with pre filled models & content."
                  rules={[
                    {
                      type: 'string',
                    },
                  ]}
                >
                  <Input disabled size="large" defaultValue={projectType || 'None'} />
                </Form.Item>
              ) : null} */}
              <Form.Item
                name={['name']}
                label="Name"
                rules={[
                  {
                    required: true,
                    type: 'string',
                  },
                ]}
              >
                <Input placeholder="Your Project Name, Ex: Pied Piper" />
              </Form.Item>

              <Form.Item
                name={['id']}
                label="Project Identifier (Auto Generated)"
                rules={[
                  {
                    required: true,
                    type: 'string',
                  },
                ]}
                hasFeedback
                validateStatus={
                  isCheckingIdentifier
                    ? 'validating'
                    : payload?.name && isValidIdentifier
                    ? 'success'
                    : payload?.name && !isValidIdentifier
                    ? 'error'
                    : ''
                }
              >
                <Input
                  placeholder={'This will auto generate once your type your project name'}
                  readOnly
                  disabled
                />
              </Form.Item>

              <Form.Item
                name={['description']}
                label="Description"
                rules={[
                  {
                    required: true,
                    type: 'string',
                  },
                ]}
              >
                <TextArea placeholder="Small Description of your Project" />
              </Form.Item>

              <Form.Item
                name={['policy']}
                valuePropName="checked"
                rules={[
                  {
                    required: true,
                    type: 'boolean',
                    transform: (value) => value || false,
                    message: 'You have to agree to these terms',
                  },
                ]}
                initialValue
              >
                <Checkbox>
                  Creating a project requires you to agree our{' '}
                  <a href="https://apito.io/user-agreement" target="_blank" rel="noreferrer">
                    User Agreement
                  </a>{' '}
                  and{' '}
                  <a href="https://apito.io/privacy-policy" target="_blank" rel="noreferrer">
                    Privacy Policy
                  </a>
                </Checkbox>
                <Row justify={'start'} style={{ marginTop: '20px' }}>
                  <Button
                    loading={loading}
                    type="primary"
                    size="middle"
                    htmlType="submit"
                    disabled={!isValidIdentifier}
                  >
                    Create Project
                  </Button>
                  <Divider type="vertical" />
                  {/*<Button onClick={() => onCancel()} size="middle">
                      Change Plan
                    </Button>
              */}
                  <Button
                    onClick={() => {
                      navigate('/projects');
                    }}
                    size="middle"
                  >
                    Back to Projects
                  </Button>
                </Row>
              </Form.Item>
            </div>
          </Col>
          <Col md={8}>
            <Form.Item
              name="project_type"
              label="Project Type"
              rules={[{ message: 'Project Type is required', required: true }]}
            >
              <FixedListSelector
                defaultValue={'Regular'}
                onSelect={(value) => {
                  setProjectType(value);
                  if (value === 'enterprise-solution') {
                    setSelectedPlan(PLAN_LIST.find((plan) => plan.key === 'enterprise-solution'));
                  }
                }}
                list={['Regular', 'SaaS']}
              />
            </Form.Item>
            <ProjectDriverForm mode="create" />
            <Form.Item
              name={['project_template']}
              label="Project Template"
              rules={[
                {
                  required: true,
                  type: 'string',
                },
              ]}
            >
              <Select
                className={styles.select}
                placeholder="Select A Template"
                onChange={(value) => {
                  setPayload({
                    ...payload,
                    project_template: value,
                  });
                }}
                defaultValue={''}
              >
                {DEMO_PROJECTS.map((db) => (
                  <Option value={db.id} key={db.key}>
                    {db.title}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name={['project_plan']}
              label="Project Plan"
              rules={[
                {
                  required: true,
                  type: 'string',
                },
              ]}
            >
              <Select
                className={styles.select}
                placeholder="Select A Plan"
                onChange={(value) => {
                  setSelectedPlan(PLAN_LIST.find((plan) => plan.key === value) || PLAN_LIST[0]);
                }}
                disabled={projectType === 'SaaS'}
                defaultValue={projectType === 'SaaS' ? 'enterprise-solution' : 'api-first'}
              >
                {PLAN_LIST.slice(0, 4).map((plan) => (
                  <Option value={plan.key} key={plan.key}>
                    {plan.title}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col
            md={8}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <PlanCard
              hideButton={true}
              item={selectedPlan}
              key={selectedPlan.key}
              onPlanClicked={(plan) => {
                //checkPlan(plan);
              }}
            />
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default ProjectCreateForm;

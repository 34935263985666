import { Avatar, Layout, Menu, MenuProps, Select, Spin, Tag } from 'antd';
import styles from './headers.module.scss';
import { Link, useLocation } from 'react-router-dom';
import LogoIcon from 'components/LogoIcon';
import {
  ApiOutlined,
  DatabaseOutlined,
  FileImageOutlined,
  FunctionOutlined,
  LayoutOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import UserMenu from 'components/menu/UserMenu';
import { TokenData } from '../../dto/project-list.dto';
import { useAuthContext } from '../../hooks/useAuth';
import { useEffect, useState } from 'react';
import { useGetTenantsQuery } from 'generated/graphql';
import { useCookies } from 'react-cookie';

type HeaderProps = {
  showNav?: boolean;
  title?: string;
  subtitle?: string;
};

// Define the type for your tenant options
interface TenantOption {
  label: string;
  value: string;
  logo: string; // URL or path to the logo image
}

// Custom DebounceSelect component
const DebounceSelect: React.FC<{
  placeholder: string;
  onChange: (value: string) => void;
  style: React.CSSProperties;
}> = ({ placeholder, onChange, style }) => {
  const { data, loading, error } = useGetTenantsQuery();
  const [options, setOptions] = useState<TenantOption[]>([]);
  const [cookies, setCookie, removeCookie] = useCookies(['temp_tenant_id']); // Initialize cookies

  useEffect(() => {
    if (data && data.getTenants) {
      const tenantOptions = data.getTenants.map((tenant: any) => ({
        label: tenant.name,
        value: tenant.id,
        logo: tenant.logo,
      }));
      setOptions(tenantOptions);
    }
  }, [data]);

  const handleChange = (value: string) => {
    if (value === undefined) {
      removeCookie('temp_tenant_id', { path: '/' });
    } else {
      setCookie('temp_tenant_id', value, { path: '/' }); // Save tenant ID in cookie
    }
    onChange(value);
  };

  if (loading) return <Spin />; // Show a loading spinner while data is being fetched
  if (error) return <div>Error loading tenants</div>; // Handle error state

  return (
    <Select
      placeholder={placeholder}
      onChange={handleChange}
      style={style}
      value={cookies.temp_tenant_id}
      optionLabelProp="label"
      allowClear
    >
      {options.map((option) => (
        <Select.Option key={option.value} value={option.value} label={option.label}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img
              src={option.logo}
              alt={option.label}
              style={{ width: 20, height: 20, marginRight: 8 }}
            />
            {option.label}
          </div>
        </Select.Option>
      ))}
    </Select>
  );
};

const Header: React.FC<HeaderProps> = ({ showNav = true, title, subtitle }) => {
  const { Header } = Layout;
  const location = useLocation();

  const { decodeTokenData } = useAuthContext();
  const tokenData: TokenData = decodeTokenData();

  //console.log('tokenData', tokenData);

  const allMenu = [
    {
      key: 'content',
      name: 'Content',
      icon: DatabaseOutlined,
      slug: '/console/contents',
      groupName: 'console',
    },
    {
      key: 'model',
      name: 'Model',
      icon: LayoutOutlined,
      slug: '/console/models',
      groupName: 'console',
    },
    {
      key: 'media',
      name: 'Media',
      icon: FileImageOutlined,
      slug: '/console/media',
      groupName: 'console',
    },
    {
      key: 'logic',
      name: 'Logic',
      icon: FunctionOutlined,
      slug: '/console/logic',
      groupName: 'console',
    },
    {
      key: 'api_explorer',
      name: 'API',
      icon: ApiOutlined,
      slug: '/console/api-explorer',
      groupName: 'console',
    },
    {
      key: 'settings',
      name: 'Settings',
      icon: SettingOutlined,
      slug: '/console/settings',
      groupName: 'console',
    },
  ];

  /* const items: MenuProps['items'] = [
    {
      label: 'Develop',
      key: '1',
    },
    {
      label: 'Stage',
      key: '2',
    },
    {
      label: 'Production',
      key: '3',
    },
  ]; */

  const onClick: MenuProps['onClick'] = (e) => {
    console.log('click ', e);
  };

  return (
    <Header className={styles.header}>
      <div className={styles.headerContainer}>
        <div className={styles.leftContent}>
          <div className={styles.logo}>
            <Link to="/">
              <LogoIcon />
            </Link>
            <div className={styles.layoutTitle}>
              <h6>{title || 'Apito'}</h6>
              {subtitle && <p>{subtitle}</p>}
            </div>
          </div>
          {showNav && (
            <Menu
              onClick={onClick}
              mode="horizontal"
              forceSubMenuRender={false}
              className={styles.menu}
              selectedKeys={[location.pathname]}
            >
              {allMenu
                .filter((m) => (tokenData?.project_access?.includes(m.key) ? true : false))
                .map(({ icon: Icon, ...m }) => (
                  <Menu.Item
                    className={styles.menuItem}
                    key={location.pathname.includes(m.slug) ? location.pathname : m.slug}
                    icon={<Icon className={styles.menuIcon} />}
                  >
                    <Link to={m.slug}>{m.name}</Link>
                  </Menu.Item>
                ))}

              {/* {projectData?.is_super_admin ? (
                <Menu.Item key="admin" icon={<UserOutlined />} style={{ bottom: 0 }}>
                  <Link to="/console/admin">Admin</Link>
                </Menu.Item>
              ) : null} */}
            </Menu>
          )}
        </div>
        <div className={styles.rightContent}>
          {/* <Dropdown.Button
            icon={<DownOutlined />}
            loading={false}
            menu={{ items }}
            onClick={() => {}}
          >
            Workspaces
          </Dropdown.Button>*/}
          {showNav && (
            <Menu mode="horizontal" forceSubMenuRender={false} className={styles.menu}>
              <Menu.Item key="link1" className={styles.menuItem}>
                {tokenData?.project_type === 1 ? (
                  <DebounceSelect
                    //mode="multiple"
                    placeholder="Choose Tenant"
                    onChange={(newValue) => {
                      window.location.reload();
                    }}
                    style={{ width: '100%' }}
                  />
                ) : null}
              </Menu.Item>
            </Menu>
          )}
          <div className={styles.userInfo}>
            <p className={styles.userName}>Hi, {tokenData?.email.split('@')[0]}..</p>
            <UserMenu avatar={''} email={tokenData?.email} role={tokenData?.project_role} />
          </div>
        </div>
      </div>
    </Header>
  );
};

export default Header;

/* eslint-disable jsx-a11y/anchor-is-valid */
import { Icon } from '@iconify/react';
import { Button, Divider, Popover, Space, Tooltip } from 'antd';
import React, { useState } from 'react';
import { ConnectionType } from 'types/contents';

type ActionColumnProps = {
  connection: ConnectionType;
  onActionClick: Function;
  projectModelName: string;
  textId: string;
  deleteLoading: boolean;
  onContentDelete: Function;
  onContentDuplicate: Function;
};

const ActionColumn = ({
  connection,
  onActionClick,
  projectModelName,
  textId,
  deleteLoading,
  onContentDelete,
  onContentDuplicate,
}: ActionColumnProps) => {
  const [isDeletePopOver, setIsDeletePopOver] = useState(false);
  const [isDuplicatePopOver, setIsDuplicatePopOver] = useState(false);
  const hideDeletePopOver = (value) => {
    setIsDeletePopOver(value);
  };
  const hideDuplicatePopOver = (value) => {
    setIsDuplicatePopOver(value);
  };
  return connection === undefined ? (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        width: 110,
        margin: '0 auto',
      }}
    >
      <Tooltip title="Add/Edit Relation">
        <Icon
          icon="mdi:relation-one-to-one"
          width="24"
          height="24"
          style={{
            backgroundColor: '#567582',
            padding: '3px',
            color: 'white',
            cursor: 'pointer',
            borderRadius: '5px',
          }}
          onClick={() =>
            onActionClick({
              action: 'relation',
              id: textId,
              model: projectModelName,
            })
          }
        />
        {/* <LinkOutlined
          onClick={() =>
            onActionClick({
              action: 'relation',
              id: textId,
              model: projectModelName,
            })
          }
        /> */}
      </Tooltip>
      <Tooltip title="Edit Data">
        <Icon
          icon="mynaui:edit-one-solid"
          width="24"
          height="24"
          style={{
            backgroundColor: '#222831',
            padding: '3px',
            color: 'white',
            cursor: 'pointer',
            borderRadius: '5px',
          }}
          onClick={() => {
            onActionClick({
              action: 'edit',
              id: textId,
              model: projectModelName,
            });
          }}
        />
        {/* <EditOutlined
          onClick={() => {
            onActionClick({
              action: 'edit',
              id: textId,
              model: projectModelName,
            });
          }}
        /> */}
      </Tooltip>
      <Tooltip title="Duplicate Data">
        <Popover
          title="Are You Sure?"
          trigger="click"
          placement="left"
          open={isDuplicatePopOver}
          onOpenChange={hideDuplicatePopOver}
          content={
            <Space>
              <button
                onClick={() => {
                  setIsDuplicatePopOver(false);
                }}
              >
                No
              </button>
              <Divider />
              <Button
                type={'primary'}
                loading={deleteLoading}
                onClick={() => onContentDuplicate(textId)}
                className="ml-1"
              >
                Yes
              </Button>
            </Space>
          }
        >
          <Icon
            icon="heroicons-outline:duplicate"
            width="24"
            height="24"
            style={{
              backgroundColor: '#30475E',
              padding: '3px',
              color: 'white',
              cursor: 'pointer',
              borderRadius: '5px',
            }}
            onClick={() => {
              setIsDuplicatePopOver(true);
            }}
          />
        </Popover>
      </Tooltip>
      <Tooltip title="Delete">
        <Popover
          title="Are You Sure?"
          trigger="click"
          placement="left"
          open={isDeletePopOver}
          onOpenChange={hideDeletePopOver}
          content={
            <Space>
              <button
                onClick={() => {
                  setIsDeletePopOver(false);
                }}
              >
                No
              </button>
              <Divider />
              <Button
                type={'primary'}
                loading={deleteLoading}
                onClick={() => onContentDelete(textId)}
                className="ml-1"
              >
                Yes
              </Button>
            </Space>
          }
        >
          <Icon
            icon="material-symbols-light:delete-outline"
            width="24"
            height="24"
            style={{
              backgroundColor: '#F05454',
              padding: '3px',
              color: 'white',
              cursor: 'pointer',
              borderRadius: '5px',
            }}
            onClick={() => {
              setIsDeletePopOver(true);
            }}
          />
        </Popover>
      </Tooltip>
    </div>
  ) : (
    <div>
      <Tooltip title="Edit Data">
        <Icon
          icon="mynaui:edit-one-solid"
          width="24"
          height="24"
          style={{
            backgroundColor: '#e73a55',
            padding: '3px',
            color: 'white',
            cursor: 'pointer',
            borderRadius: '5px',
          }}
          onClick={() =>
            onActionClick({
              tagMore: true,
              action: 'edit',
              id: textId,
              model: projectModelName,
            })
          }
        />
        {/* <EditOutlined
          onClick={() =>
            onActionClick({
              tagMore: true,
              action: 'edit',
              id: textId,
              model: projectModelName,
            })
          }
        /> */}
      </Tooltip>
    </div>
  );
};

export default ActionColumn;

import { Select } from 'antd';
// import { capitalize } from 'lodash';

const { Option } = Select;

type FixedListSelectorProps = {
  list?: Record<string, any>[] | string[] | boolean[];
  islabelValue?: boolean;
  placeholder?: string;
  loading?: boolean;
  allowClear?: boolean;
  disabled?: boolean;
  style?: any;
  className?: string;
  value?: string;
  onSelect?: (value: string) => void;
  defaultValue?: string;
};

const FixedListSelector: React.FC<FixedListSelectorProps> = ({
  list,
  islabelValue,
  allowClear = true,
  style,
  value,
  loading,
  className,
  onSelect,
  defaultValue,
  ...props
}) => {
  // const getCapitalizeLabel = (label) => {
  //   if (typeof label === 'string' && label.toLowerCase() === 'json') return 'JSON';
  //   return capitalize(label);
  // };

  return (
    <Select
      placeholder="Select a Choice"
      allowClear={allowClear}
      loading={loading}
      style={style}
      className={className}
      value={value}
      onSelect={onSelect}
      {...props}
      defaultValue={defaultValue}
      // optionFilterProp="children"
      // filterOption={(input, option) => option?.children?.toLowerCase().includes(input.toLowerCase()) ?? -1 >= 0}
    >
      {!islabelValue && list
        ? list.map((list_item, index) => (
            <Option value={list_item} key={index}>
              <span>{list_item}</span>
            </Option>
          ))
        : // pass a list of object with islabelValue
        islabelValue && list
        ? list.map((list_item, index) => (
            <Option value={list_item?.value} key={index}>
              <span>{list_item?.label}</span>
            </Option>
          ))
        : null}
    </Select>
  );
};

export default FixedListSelector;

import React, { useEffect, useState } from 'react';
import { Button, Col, Empty, Form, Input, Row, Select, Switch } from 'antd';
import { ProjectModel, useGetAllModelsQuery } from 'generated/graphql';
import localsData from 'data/setting/locals.json';
import Loader from 'components/loader/Loader';

const { Option } = Select;

type GeneralFormProps = {
  project: ProjectModel;
  onFinish: (value: Record<string, never>) => void;
  submissionLoading: boolean;
};

const GeneralForm: React.FC<GeneralFormProps> = ({ project, onFinish, submissionLoading }) => {
  const [modelList, setModelList] = React.useState([]);
  const [payload, setPayload] = useState({});
  const localsRepo = Object.entries(localsData);

  const { loading: modelsListLoading, data: modelData } = useGetAllModelsQuery();

  useEffect(() => {
    if (modelData) {
      const modelList = modelData?.projectModelsInfo?.map((item) => item.name) ?? [];
      setModelList([...modelList]);
    }
  }, [modelData]);

  const onValuesChange = (values) => {
    setPayload({ ...payload, ...values });
  };

  return (
    <Form
      name="general-setting"
      layout="vertical"
      onValuesChange={onValuesChange}
      onFinish={onFinish}
      initialValues={{ remember: true }}
    >
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item name="id" label="Project ID" initialValue={project?.id}>
            <Input readOnly disabled />
          </Form.Item>
          <Form.Item
            name="name"
            label="Project Name"
            initialValue={project?.name}
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="description"
            label="Project Description"
            initialValue={project?.description}
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Content Languages">
            <Form.Item
              name={['settings', 'locals']}
              initialValue={project.settings?.locals}
              style={{ marginTop: '5px', marginBottom: 5 }}
            >
              <Select
                showSearch
                mode="multiple"
                placeholder="Select Languages"
                optionFilterProp="children"
                style={{ width: '100%' }}
              >
                {localsRepo.length > 0
                  ? localsRepo.map((d, i) => (
                      <Option key={i} label={d[0]} value={d[0]}>
                        {d[1]}
                      </Option>
                    ))
                  : null}
              </Select>
            </Form.Item>
          </Form.Item>
          <Form.Item label="Default Contant Language">
            <Form.Item
              name={['settings', 'default_locale']}
              initialValue={project?.settings?.default_locale}
              style={{ marginTop: '5px', marginBottom: 5 }}
            >
              <Select
                placeholder="Select Language"
                optionFilterProp="children"
                style={{ width: '100%' }}
              >
                {project.settings?.locals.length > 0 ? (
                  project.settings?.locals.map((d, i) => (
                    <Option key={i} label={localsRepo[d]} value={d}>
                      {localsRepo[d]}
                    </Option>
                  ))
                ) : (
                  <Option> Select Content Language First</Option>
                )}
              </Select>
            </Form.Item>
          </Form.Item>
          <Form.Item
            initialValue={project?.settings?.enable_revision_history}
            name={['settings', 'enable_revision_history']}
            label="Enable Document History"
            valuePropName="checked"
          >
            <Switch disabled={true} />
          </Form.Item>
          <Form.Item
            initialValue={project?.settings?.system_graphql_hooks}
            name={['settings', 'system_graphql_hooks']}
            label="Enable System Graphql Hooks"
            valuePropName="checked"
          >
            <Switch disabled={true} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={['settings', 'tenant_model_name']}
            label="Tenant Model"
            initialValue={project?.settings?.tenant_model_name}
          >
            <Select
              showSearch
              placeholder="Select a Model"
              notFoundContent={
                modelsListLoading ? (
                  <Loader size="small" />
                ) : (
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )
              }
              optionFilterProp="children"
              style={{ width: '100%' }}
            >
              {modelList.length > 0
                ? modelList.map((name, i) => (
                    <Select.Option key={i} label={name} value={name}>
                      {name}
                    </Select.Option>
                  ))
                : null}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Button type="primary" htmlType="submit" loading={submissionLoading}>
        SAVE
      </Button>
    </Form>
  );
};

export default GeneralForm;

import { Button, Drawer } from 'antd';
import MainLayout from 'components/layouts/MainLayout';
import { useGetPermissionsAndScopesQuery, useGetProjectRolesQuery } from 'generated/graphql';
import React from 'react';
import RolesDrawer from './RolesDrawer';
import RolesTable from './RolesTable';

const RolesContainer = () => {
  const [isDrawer, setIsDrawer] = React.useState(false);
  const [roleKey, setRoleKey] = React.useState('');

  const { data: rolesData } = useGetProjectRolesQuery();
  const { data: rolesScopeData } = useGetPermissionsAndScopesQuery();

  const onCreateRole = () => {
    setRoleKey('');
    setIsDrawer(true);
  };

  const onEditRole = (roleKey) => {
    setRoleKey(roleKey);
    setIsDrawer(true);
  };

  return (
    <MainLayout
      title="Roles"
      subtitle="Limit Who Can Use & See What!"
      breadcrumList={['Setting', 'Roles']}
      extra={[
        <Button type="primary" onClick={onCreateRole}>
          CREATE ROLE
        </Button>,
      ]}
    >
      <RolesTable onEditRole={onEditRole} rolesData={rolesData} />

      <Drawer // Field Configuration  - Drawer
        title={roleKey === '' ? 'ADD A ROLE' : 'EDIT ROLE'}
        width={860}
        closable
        destroyOnClose
        onClose={() => {
          setIsDrawer(false);
        }}
        extra={<Button type="link" onClick={() => setIsDrawer(false)}>{`< Back`}</Button>}
        visible={isDrawer}
      >
        <RolesDrawer
          rolesData={rolesData}
          rolesScopeData={rolesScopeData?.listPermissionsAndScopes}
          roleKey={roleKey}
        />
      </Drawer>
    </MainLayout>
  );
};

export default RolesContainer;

import { Menu, Space } from 'antd';
import { ContentContext } from 'context/ContentContext';
import { useGetAllModelsQuery } from 'generated/graphql';
import { useContext, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styles from './sidebars.module.scss';
import { FileTextOutlined, UserOutlined } from '@ant-design/icons';
import { Icon } from '@iconify/react';
import { useAuthContext } from 'hooks/useAuth';
import { TokenData } from 'dto/project-list.dto';

const ContentSidebar = ({ tokenData }: { tokenData: TokenData }) => {
  const { data: modelList } = useGetAllModelsQuery();
  const firstModel = modelList?.projectModelsInfo?.[0]?.name;
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  const { dispatch } = useContext(ContentContext);

  useEffect(() => {
    if (modelList?.projectModelsInfo?.length) {
      if (!params?.model) {
        navigate(`${firstModel}`);
      }
    }
    const obj = modelList?.projectModelsInfo?.find((item) => item.name === params?.model);
    dispatch({
      type: 'SET_TARGET',
      payload: {
        target: obj?.name,
        single_page: obj?.single_page,
        single_page_uuid: obj?.single_page_uuid,
        has_connections: obj?.has_connections,
      },
    });
  }, [dispatch, firstModel, modelList?.projectModelsInfo, navigate, params?.model]);

  return (
    <div className={styles.menuWrapper}>
      <div className={styles.menuGroup}>
        <p className={styles.groupTitle}>Contents</p>
        <Menu mode="inline" className={styles.menu} selectedKeys={[location?.pathname]}>
          {modelList?.projectModelsInfo?.map((item, i) => (
            <Menu.Item
              key={`/console/contents/${item.name}`}
              onClick={() => {
                dispatch({
                  type: 'SET_TARGET',
                  payload: {
                    target: item.name,
                    single_page: item.single_page,
                    single_page_uuid: item.single_page_uuid,
                    has_connections: item.has_connections,
                  },
                });
                navigate(`/console/contents/${item.name}`);
              }}
              className={styles.menuItem}
            >
              <div className={styles.menuDetails}>
                <Space>
                  {item.single_page ? <FileTextOutlined /> : null}
                  {item.is_tenant_model ? (
                    <Icon icon="hugeicons:store-04" width="18" height="18" />
                  ) : null}
                  {item.name}
                </Space>
              </div>
              {/* {
                  // model && item.name === state.target &&
                  item.name === 'user' && item.system_generated ? (
                    <UserOutlined />
                  ) : item.single_page ? (
                    <FileTextOutlined />
                  ) : null
                } */}
            </Menu.Item>
          ))}
        </Menu>
      </div>
    </div>
  );
};

export default ContentSidebar;

import { Layout, Modal, Select, Space, Tag } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import Sider from 'antd/lib/layout/Sider';
import ContentSidebar from 'components/sidebars/ContentSidebar';
import { ContentContext, ContentReducer } from 'context/ContentContext';
import { Reducer, useEffect, useReducer, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import styles from './layouts.module.scss';
import dayjs from 'dayjs';
import type { SelectProps } from 'antd';
import { httpService } from 'utils/httpService';
import type { CustomTagProps } from 'rc-select/lib/BaseSelect';
import DebounceSelect from 'components/forms/DebouncedSelect';

// Usage of DebounceSelect
interface UserValue {
  label: string;
  value: string;
}

async function fetchUserList(username: string): Promise<UserValue[]> {
  console.log('fetching user', username);

  return fetch('https://randomuser.me/api/?results=5')
    .then((response) => response.json())
    .then((body) =>
      body.results.map(
        (user: { name: { first: string; last: string }; login: { username: string } }) => ({
          label: `${user.name.first} ${user.name.last}`,
          value: user.login.username,
        })
      )
    );
}

const ContentsLayout = ({ tokenData }) => {
  const [state, dispatch] = useReducer<Reducer<any, any>>(ContentReducer, { target: '' });

  const [value, setValue] = useState<UserValue[]>([]);

  return (
    <ContentContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      <Layout hasSider className={styles.layoutWrapper}>
        <Sider className={styles.sidebar}>
          <ContentSidebar tokenData={tokenData} />
        </Sider>
        <div className={styles.layoutContent}>
          <Layout className={styles.layoutContentInner}>
            <Content>
              {/* {payment_due_date !== '' && payment_due_date !== undefined ? (
                <Modal
                  title="Payment is Due"
                  open={isModalOpen}
                  onOk={handleOk}
                  onCancel={handleCancel}
                  cancelText="Back to Projets"
                >
                  <p>{`Payment is ${dayjs().from(
                    dayjs(tokenData?.payment_due_date),
                    true
                  )} due`}</p>
                  <p>Please Contact Support Administrator</p>
                </Modal>
              ) : ( */}
              <Outlet />
            </Content>
          </Layout>
        </div>
      </Layout>
    </ContentContext.Provider>
  );
};

export default ContentsLayout;

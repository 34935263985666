import { Button, Checkbox, Col, Form, Input, PageHeader, Row, Typography } from 'antd';
import { useEffect, useState } from 'react';

import FixedListSelector from 'components/inputs/FixedListSelector';
import {
  Plugin_Type_Enum,
  useGetAllModelsQuery,
  useListPluginIdsQuery,
  useUpsertFunctionToProjectMutation,
} from 'generated/graphql';
import { successMessage } from 'utils/message';
import styles from './functionForm.module.scss';
import { GET_ALL_FUNCTION_INFO } from '../../../graphql/queries/functions';

const { Text } = Typography;
const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const validateMessages = {
  // eslint-disable-next-line no-template-curly-in-string
  required: '${name} is required!',
};

function CreateLogic(props) {
  const [resModelList, setResModelList] = useState([]);
  const [form] = Form.useForm();

  const { error, data: modelList } = useGetAllModelsQuery();
  if (error) console.log(error);

  useEffect(() => {
    if (modelList) {
      // console.log('Log: onFinish -> modelList', modelList)
      const resModelArr = modelList?.projectModelsInfo?.map((item) => item.name) ?? [];
      setResModelList(['JSON', ...resModelArr]);
    }
  }, [modelList]);

  const updateCache = (cache, { data }) => {
    const resData = data.upsertFunctionToProject;
    // console.log('Log: updateCache -> resData', resData)
    const existingData = cache.readQuery({
      query: GET_ALL_FUNCTION_INFO,
    })?.projectFunctionsInfo;
    // console.log('Log: updateCache -> existingData', existingData)

    cache.writeQuery({
      query: GET_ALL_FUNCTION_INFO,
      data: {
        projectFunctionsInfo: [...existingData, resData],
      },
    });
  };

  const { data: pluginList } = useListPluginIdsQuery({
    variables: {
      type: Plugin_Type_Enum.Function,
    },
    onCompleted: (data) => {},
  });

  const [createFunction, { loading }] = useUpsertFunctionToProjectMutation({
    onCompleted: (data) => {
      console.log('Log: data', data);
      successMessage('Function Created Successfully!');
    },
    update: updateCache,
  });

  const onFinish = async (values) => {
    await createFunction({
      variables: {
        ...values,
      },
    });
  };

  return (
    <PageHeader title="Create an Apito function">
      <Form
        {...layout}
        form={form}
        name="function-form"
        layout="horizontal"
        onFinish={onFinish}
        validateMessages={validateMessages}
        initialValues={{ remember: false }}
      >
        <Row>
          <Col span={24} lg={{ span: 11 }}>
            <Form.Item
              label="Function Name"
              name="name"
              rules={[{ message: 'Function name is required', required: true }]}
              extra="This function will appear in your GraphQL Mutation & RESTful API after its creation"
            >
              <Input placeholder="Ex: veirfyPayment, processOrder,  sendEmail, exportReports" />
            </Form.Item>

            <Form.Item>
              <Text className={styles.title_2}>Function Settings</Text>
            </Form.Item>

            <Form.Item
              name="graphql_schema_type"
              label="GraphQL Schema Type"
              rules={[{ message: 'GraphQL Schema Type is required', required: true }]}
            >
              <FixedListSelector list={['Mutation', 'Query']} />
            </Form.Item>

            <Form.Item
              name="function_language"
              label="Language"
              rules={[{ message: 'Function Lanuage is required', required: true }]}
            >
              <FixedListSelector list={['Javascript', 'Golang', 'Python']} />
            </Form.Item>

            <Form.Item
              name="function_provider_id"
              label="Execution Provider"
              rules={[{ message: 'Execution Provider is required', required: true }]}
            >
              <FixedListSelector list={pluginList?.listPluginIds?.plugins} />
            </Form.Item>

            <Form.Item label="Request Parameter">
              <Row gutter={8} align="middle">
                <Col flex="auto">
                  <Form.Item name="request" noStyle rules={[{ required: true }]}>
                    <FixedListSelector list={resModelList} />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="request_payload_is_optional" valuePropName="checked" noStyle>
                    <Checkbox>Is Optional</Checkbox>
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>

            <Form.Item label="Response Parameter">
              <Row gutter={8} align="middle">
                <Col flex="auto">
                  <Form.Item name="response" noStyle rules={[{ required: true }]}>
                    <FixedListSelector list={resModelList} />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="response_is_array" valuePropName="checked" noStyle>
                    <Checkbox>Is Array</Checkbox>
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" className="mt-4" loading={loading}>
                CREATE
              </Button>
            </Form.Item>
          </Col>

          {/* <Col span={24} lg={{ span: 11, offset: 2 }}>

            <Form.Item>
              <Text className={styles.title_2}>Environment Variables</Text>
            </Form.Item>

            <Row>
              <Col span={10}>
                <Form.Item>
                  <Input placeholder="Name" />
                </Form.Item>
              </Col>

              <Col span={13} offset={1}>
                <Form.Item >
                  <Input placeholder="Value" />
                </Form.Item>
              </Col>
            </Row>
            <Button className="mt-2" style={{ background: '#E7ECF3', border: '1px solid #E7ECF3', color: 'var(--themeColor)' }} >
              ADD MORE
            </Button>

          </Col> */}
        </Row>
      </Form>
    </PageHeader>
  );
}

export default CreateLogic;

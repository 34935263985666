import { gql } from '@apollo/client';

// first column's model list
export const GET_ALL_MODELS = gql`
  query getAllModels {
    projectModelsInfo {
      name
      single_page
      single_page_uuid
      system_generated
      has_connections
      is_tenant_model
    }
  }
`;

// // for content table data
export const GET_SELECTED_MODEL_LIST_DETAILED = gql`
  query getSelectedModelListDetailed(
    $model: String!
    $limit: Int
    $page: Int
    $search: String
    $where: JSON
  ) {
    getModelData(model: $model, limit: $limit, page: $page, search: $search, where: $where) {
      results {
        id
        data
        meta {
          created_at
          updated_at
          status
        }
      }
      count
    }
  }
`;

// import useAuth from 'context/AuthContext';
// import ContentHeader from 'components/layout/header/ContentHeader';
import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';
import { Avatar, Tabs } from 'antd';
import GraphqlExplorerWrapper from './GraphqlExplorerWrapper';
import { CLIENT_GRAPHQL_URL, CLIENT_GRAPHQL_URL_v2, REST_DOC_URL } from '../../configs';
// import { REST_DOC_URL, CLIENT_GRAPHQL_URL } from 'config';
// import { Fragment } from 'react';
// import ApiExplorer from './graphql/ApiExplorer.js';
import styles from './apiExplorer.module.scss';
import { useAuthContext } from '../../hooks/useAuth';

const { TabPane } = Tabs;

type APIExplorerProps = {
  //
};

const APIExplorer: React.FC<APIExplorerProps> = () => {
  const { decodeTokenData } = useAuthContext();
  const tokenData = decodeTokenData();

  const restUrl = `${REST_DOC_URL}/${tokenData.project_id}`;

  const interceptor = function (request) {
    if (request.url.includes('system/doc')) {
      request.credentials = 'include';
    }
    return request;
  };

  return (
    <div className={styles.apiExplorer}>
      <Tabs
        className={styles.tabs}
        defaultActiveKey="0"
        type="card"
        size="small"
        style={{ marginTop: 10 }}
      >
        <TabPane
          className={styles.tab}
          tab={
            <span>
              <Avatar
                size={18}
                style={{ marginRight: 8 }}
                src="/static/pages/console/explorer/graphql-black.png"
              />
              GraphQL API [Stable]
            </span>
          }
          key="0"
        >
          <GraphqlExplorerWrapper url={CLIENT_GRAPHQL_URL} />
        </TabPane>
        <TabPane
          className={styles.tab}
          tab={
            <span>
              <Avatar
                size={18}
                style={{ marginRight: 8 }}
                src="/static/pages/console/explorer/rest.svg"
              />
              REST API [ Alpha ]
            </span>
          }
          key="1"
        >
          <div style={{ marginLeft: 100, textAlign: 'center' }}>
            <span>
              <p>
                Go to <strong>Settings API Secrets</strong> and Generate an api key to be used in
                Authorize
              </p>
            </span>
          </div>
          <div style={{ height: 'calc(100vh - 160px)', overflow: 'auto' }}>
            <SwaggerUI
              url={restUrl}
              requestInterceptor={interceptor}
              //withCredentials={true}
              onComplete={(ui) => {
                //ui.preauthorizeApiKey('api_key', `Bearer `);
              }}
            />
          </div>
        </TabPane>
        <TabPane
          className={styles.tab}
          tab={
            <span>
              <Avatar
                size={18}
                style={{ marginRight: 8 }}
                src="/static/pages/console/explorer/graphql-black.png"
              />
              GraphiQL Explorer V2 (GraphQL) [Experimental Beta ]
            </span>
          }
          key="2"
        >
          <GraphqlExplorerWrapper url={CLIENT_GRAPHQL_URL_v2} />
        </TabPane>
        {tokenData?.is_super_admin === 'true' && (
          <TabPane
            className={styles.tab}
            tab={
              <span>
                <Avatar
                  size={18}
                  style={{ marginRight: 8 }}
                  src="/static/pages/console/explorer/graphql-black.png"
                />
                System GraphQL
              </span>
            }
            key="3"
          ></TabPane>
        )}
        {/* ) : null} */}
      </Tabs>
    </div>
  );
};

export default APIExplorer;
